import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_table_action_button = _resolveComponent("tm-table-action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, null, {
    menu: _withCtx(() => [
      _createVNode(_component_tm_dropdown_item, {
        icon: "visibility",
        label: "View details",
        to: _ctx.toDetails
      }, null, 8, ["to"]),
      _createVNode(_component_tm_dropdown_item, {
        icon: "edit",
        label: "Edit"
      }),
      (_ctx.rule.status.name === 'active')
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 0,
            icon: "pause",
            label: "Pause"
          }))
        : (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 1,
            icon: "play_arrow",
            label: "Resume"
          })),
      _createVNode(_component_tm_dropdown_item, {
        icon: "delete",
        label: "Delete",
        onClick: _ctx.openAutomationRuleDeleteModal
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_table_action_button)
    ]),
    _: 1
  }))
}