import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = { class: "lh-20 ml-2" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_badges = _resolveComponent("tm-badges")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_services_automation_rules_dropdown = _resolveComponent("services-automation-rules-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": "rules"
  }, {
    "body-cell-rule-name-slot": _withCtx(({ row }) => [
      _createVNode(_component_router_link, {
        to: _ctx.getTo(row.id),
        class: "row align-center font-color-inherit font-weight-normal blue-on-hover no-wrap"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_icon, {
            name: "tmi-settings-play",
            size: "large",
            class: "distinct--text"
          }),
          _createElementVNode("div", _hoisted_1, _toDisplayString(row.name), 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-account-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_person, {
        name: row.account.fullName,
        "avatar-color": row.account.avatarColor,
        "avatar-size": "small"
      }, null, 8, ["name", "avatar-color"])
    ]),
    "body-cell-keywords-slot": _withCtx(({ row }) => [
      (!row.specialKeywords)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Any keyword "))
        : (_openBlock(), _createBlock(_component_tm_badges, {
            key: 1,
            items: row.specialKeywords.map((k) => k.toUpperCase()),
            static: "",
            class: "my-0",
            size: "small"
          }, null, 8, ["items"]))
    ]),
    "body-cell-status-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(row.status)), null, 16)
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_services_automation_rules_dropdown, {
        rule: props.row
      }, null, 8, ["rule"])
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}