
import {
  defineComponent,
  ref,
  computed
} from 'vue'
import ServicesAutomationRulesEmpty from '@/components/pages/services/automationRules/ServicesAutomationRulesEmpty.vue'
import ServicesAutomationRulesTable from '@/components/pages/services/automationRules/ServicesAutomationRulesTable.vue'
import ServicesAutomationRulesFilter from '@/components/pages/services/automationRules/ServicesAutomationRulesFilter.vue'
import { useModes } from '@/compositions/modes'
import { getTableData } from '@/services/tableService'
import type { AutomationRule } from '@/definitions/services/automationRules/types'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    ServicesAutomationRulesEmpty,
    ServicesAutomationRulesTable,
    ServicesAutomationRulesFilter,
  },
  setup() {
    const { isEmptyMode } = useModes()

    const search = ref('')
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Rule name', value: 'rule-name-slot' },
      { text: 'Account', value: 'account-slot' },
      { text: 'Keywords', value: 'keywords-slot' },
      { text: 'Auto-responder', value: 'autoResponder' },
      { text: 'Status', value: 'status-slot' },
    ])

    const tableItems = getTableData('automationRules') as AutomationRule[]

    const filteredTableItems = computed(() => tableItems.filter((el) => (el.name.toLocaleLowerCase().includes(search.value.toLocaleLowerCase()))))

    return {
      isEmptyMode,
      search,
      tableHeaders,
      filteredTableItems,
    }
  },
})
